<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请选择支付状态">
            <el-select
              placeholder="请选择支付状态"
              v-model="search.trade_state"
            >
              <el-option label="全部" :value="''" />
              <el-option label="未支付" :value="0" />
              <el-option label="已支付" :value="1" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请选择支付时间">
            <el-date-picker
              v-model="startEnd"
              type="daterange"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-form-item>
            <el-button type="primary" @click="onSearch"> 搜索 </el-button>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <el-button
            :loading="loading"
            type="success"
            @click="onPayTheFeesexport"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="oldStudent"
      border
      style="width: 100%; margin-top: 20px"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <!-- <el-table-column fixed type="selection" width="55" /> -->
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="orderid" label="订单号" width="200" />
      <el-table-column prop="amount" label="支付金额" width="180" />
      <el-table-column prop="trade_state" label="支付状态" width="180">
        <template v-slot="{ row }">
          <span v-if="row.trade_state === '0'">未支付</span>
          <span v-else-if="row.trade_state === '1'">已支付</span>
        </template>
      </el-table-column>
      <el-table-column prop="success_time" label="支付时间" width="180" />
      <el-table-column prop="trade_type" label="交易类型" width="180" />

      <el-table-column prop="trade_state_desc" label="交易描述" width="180" />

      <el-table-column prop="created_at" label="创建时间" width="180" />
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="search.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="search.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <infirmary-examine-from
      v-model="oldStudentShow"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></infirmary-examine-from>
  </div>
</template>

<script setup>
// import { useRouter } from 'vue-router'
// import ElementPlus from 'element-plus'
// import 'element-plus/lib/theme-chalk/index.css'
import { ref, watch } from 'vue'
import { infirmaryAPI, infirmaryReportAPI } from '@/api/finance'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
// import { ElMessage } from 'element-plus'
import infirmaryExamineFrom from './components/infirmaryExamineFrom.vue'
// 搜索表单
const search = ref({
  page: 1,
  total: 0,
  limit: 10,
  id: '',
  trade_state: '',
  start_time: '', // 缴费日期
  end_time: ''
})
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
// 搜索
const startEnd = ref([new Date(''), new Date('')])
watch(
  () => startEnd,
  (val) => {
    if (!val.value) {
      search.value.start_time = ''
      search.value.end_time = ''
    } else {
      search.value.start_time = val.value[0]
      search.value.end_time = val.value[1]
    }
  },
  {
    deep: true
  }
)
const onSearch = () => {
  // search.value.start_time = startEnd.value[0]
  // search.value.end_time = startEnd.value[1]
  onListOldStudent()
}

/**
 *  表格 S
 */
const oldStudent = ref([])
const onListOldStudent = async () => {
  const { data, total } = await infirmaryAPI(search.value)
  oldStudent.value = data
  search.value.total = total
  // console.log('data', dsata)
}
onListOldStudent()
// const paymentTime = ref([])
// watch(
//   () => paymentTime,
//   (val) => {
//     search.value.start_time = val.value[0]
//     search.value.end_time = val.value[1]
//   },
//   {
//     deep: true
//   }
// )
// 修改
const studentID = ref({})
const onEditBtn = ({ id }) => {
  studentID.value = id
  oldStudentShow.value = true
  console.log('id', id)
}
// 导出
const loading = ref(false)
const onPayTheFeesexport = async () => {
  loading.value = true
  const data = await infirmaryReportAPI(search.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
  loading.value = false
}

const ids = ref([])
// 多选框选中数据
const handleSelectionChange = (selection) => {
  // 获取所有选中项的gh(工号)属性的值
  ids.value = selection.map((item) => item.orderid).join()
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  search.value.limit = val
  onListOldStudent()
}

// 页码值
const handleCurrentChange = (val) => {
  search.value.page = val
  onListOldStudent()
}

/**
 *  分页 E
 */

/** 修改 S */
const oldStudentShow = ref(false)
/** 修改 E */
</script>

<style lang="scss" scoped></style>
